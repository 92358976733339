import { useEffect, createRef } from "react";
import { Wrapper, PlayerWrapper, OverlayWrapper } from "./app.styles";

const STEP_PERCENT = 10;

const useDrag = (cameraAttribute) => {
  const dragConfig = createRef();

  const handleDragStart = (e) => {
    const cameraAttr = window.threekit.configurator
      .getDisplayAttributes()
      .find((el) => el.name === cameraAttribute);
    if (!dragConfig.current.cameraValues) {
      dragConfig.current.cameraValues = cameraAttr.values;
    }
    dragConfig.current.isDragging = true;
    dragConfig.current.initialX = e.clientX || e.touches[0].clientX;
    cameraAttr.values.forEach((el, idx) => {
      if (el.value === cameraAttr.value) {
        dragConfig.current.startCameraIdx = idx;
        dragConfig.current.currentCameraIdx = idx;
      }
    });
  };

  useEffect(() => {
    const handleDragEnd = () => (dragConfig.current.isDragging = false);

    const handleDrag = (e) => {
      if (dragConfig.current.isDragging === false) return;
      const delta =
        (e.clientX || e.touches[0].clientX) - dragConfig.current.initialX;
      const deltaAbs = Math.round((Math.abs(delta) / window.innerWidth) * 100);
      const deltaIdx = Math.floor(deltaAbs / STEP_PERCENT);
      const direction = delta > 0 ? "right" : "left";
      if (
        deltaIdx >= 1 &&
        deltaIdx !==
          Math.abs(
            dragConfig.current.startCameraIdx -
              dragConfig.current.currentCameraIdx
          )
      ) {
        let nextCameraIdx;
        if (direction === "right") {
          nextCameraIdx =
            (dragConfig.current.startCameraIdx + deltaIdx) %
            dragConfig.current.cameraValues.length;
        } else {
          nextCameraIdx =
            (Math.ceil(100 / STEP_PERCENT) *
              (dragConfig.current.cameraValues.length - 1) +
              dragConfig.current.startCameraIdx -
              deltaIdx) %
            dragConfig.current.cameraValues.length;
        }
        if (
          typeof nextCameraIdx === "number" &&
          dragConfig.current.currentCameraIdx !== nextCameraIdx
        ) {
          dragConfig.current.currentCameraIdx = nextCameraIdx;
          window.threekit.configurator.setConfiguration({
            [cameraAttribute]:
              dragConfig.current.cameraValues[nextCameraIdx].value,
          });
        }
      }
    };

    (() => {
      dragConfig.current = { isDragging: false, initialX: undefined };

      //  Mouse devices
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("mousemove", handleDrag);
      //  Touch devices
      document.addEventListener("touchend", handleDragEnd);
      document.addEventListener("touchmove", handleDrag);
    })();
    return () => {
      //  Mouse devices
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("mousemove", handleDrag);
      //  Touch devices
      document.removeEventListener("touchend", handleDragEnd);
      document.removeEventListener("touchmove", handleDrag);
    };
  });

  return handleDragStart;
};

const usePlayer = () => {
  useEffect(() => {
    (async () => {
      await new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = `https://preview.threekit.com/app/js/threekit-player-bundle.js`;
        script.id = "threekit-player-bundle";
        script.onload = () => resolve();
        document.head.appendChild(script);
      });

      const player = await window.threekitPlayer({
        el: document.getElementById("tk-player"),
        // authToken: "a78ce53b-58a8-410a-94bf-11c8410952ec",
        // assetId: "24b6002a-f552-43ea-b806-7e845f5a5e3b",
        authToken: process.env.REACT_APP_THREEKIT_PREVIEW_PUBLIC_TOKEN,
        assetId: process.env.REACT_APP_THREEKIT_PREVIEW_ASSET_ID,
      });
      window.threekit = {
        player: player,
        configurator: await player.getConfigurator(),
      };
    })();
  });

  return true;
};

const App = () => {
  usePlayer();
  const handleDragStart = useDrag("Camera Angle");
  // const zoomConfig = createRef();

  // const handlePointerDown = (ev) => {
  //   console.log("pointer down");
  //   if (!zoomConfig.current)
  //     zoomConfig.current = { pointers: [], diff: undefined };

  //   zoomConfig.current.pointers.push(ev);
  // };

  // const handlePointerMove = (ev) => {
  //   if (!zoomConfig.current) return;

  //   for (let i = 0; i < zoomConfig.current.pointers.length; i++) {
  //     if (ev.pointerId === zoomConfig.current.pointers[i].pointerId) {
  //       zoomConfig.current.pointers[i] = ev;
  //       break;
  //     }
  //   }

  //   if (zoomConfig.current.pointers.length < 2) return;

  //   const distance = Math.abs(
  //     zoomConfig.current.pointers[0].clientX -
  //       zoomConfig.current.pointers[1].clientX
  //   );

  //   const el = document.getElementById("actions");
  //   if (zoomConfig.current.diff) {
  //     el.innerHTML = distance;
  //     if (zoomConfig.current.diff > distance) {
  //       el.style.background = "purple";
  //       console.log("zooming in");
  //     } else if (zoomConfig.current.diff < distance) {
  //       el.style.background = "orange";
  //       console.log("zooming out");
  //     }
  //   }

  //   zoomConfig.current.diff = distance;
  // };

  // const handlePointerUp = (ev) => {
  //   console.log("pointer up");

  //   // Remove this event from the target's cache
  //   for (let i = 0; i < zoomConfig.current.pointers.length; i++) {
  //     if (zoomConfig.current.pointers[i].pointerId === ev.pointerId) {
  //       zoomConfig.current.pointers.splice(i, 1);
  //       break;
  //     }
  //   }
  //   if (zoomConfig.current.pointers.length < 2) {
  //     zoomConfig.current.diff = undefined;
  //   }
  // };

  // useEffect(() => {
  //   (() => {
  //     document.addEventListener("pointermove", handlePointerMove);
  //   })();
  // });

  return (
    <Wrapper>
      <PlayerWrapper
        id="tk-player"
        onClick={() => console.log("player clicked")}
      />
      <OverlayWrapper
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        // onPointerDown={handlePointerDown}
        // onPointerUp={handlePointerUp}
        // onPointerLeave={handlePointerUp}
        // onPointerCancel={handlePointerUp}
        // onPointerOut={handlePointerUp}
        id="actions"
      />
    </Wrapper>
  );
};

export default App;
