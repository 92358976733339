import styled from "styled-components";

const HEIGHT = "80vh";
const WIDTH = "100vw";

export const Wrapper = styled.div`
  height: ${HEIGHT};
  width: ${WIDTH};

  position: relative;
`;

export const PlayerWrapper = styled.div`
  height: ${HEIGHT};
  width: ${WIDTH};

  position: absolute;
  top: 0;
  left: 0;

  /* background: green; */
`;

export const OverlayWrapper = styled.div`
  height: ${HEIGHT};
  width: ${WIDTH};

  position: absolute;
  top: 0;
  left: 0;

  /* background: red; */
`;
